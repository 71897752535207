import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faDiscord, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

const AboutPage = () => {
    return (

        <div className="pt-5 pb-5" style={{ background: "#99ffff", color: "#ba00ba", alignItems: "center" }}>
            <Container>
                <Row>
                    <Col xs lg={6} style={{ background: "#fff" }}>
                        <header id="about">
                            <h1 className='pt-5 pb-5 fw-bold fst-italic text-uppercase text-center'>WHAT ARE BOTO NFT?</h1>
                            <h4 className='p-3'>The adorable animals known as the pink dolphin are in danger of extinction as they are killed as bait to catch other fish.</h4>
                            <h4 className='p-3'>Thinking of being another voice to call attention to this cruelty, I'm making these cute nfts, which in addition to brightening in art form, show a serious problem for many people who are unaware of the problem.</h4>
                            <h4 className='p-3'>But I don't just want to show the problem, I want to try to act somehow. Therefore, I emphasize that part of the profits from the art will go to people and/or organizations that try to help in some way.</h4>
                            <h4 className='p-3'>This help will not be decided by me, but by the community that owns the NFTs, who will be invited to vote from time to time. In the future it will be explained in detail how it will work - I'm still thinking about it.</h4>
                            <h4 className='p-3'>In addition to helping a noble cause, all NFT Boto holders will receive part of the royalties on trading in the marketplaces, as well as participating in an exclusive area on our Discord, where they can have direct contact with me and interact with other art holders, as well as well as to suggest and/or decide where the funds collected in the period will be destined. There will also be a Market where sales of exclusive Boto products will be carried out, which will have at least 50% of the profit reverted to institutions supporting the animal cause and other types of philanthropy, as well as 15% reverted between NFT holders directly in the portfolio after the calculation of each period (to be defined if it will be monthly, quarterly, half-yearly, etc).</h4>
                        </header>
                    </Col>
                    <Col xs lg={6} style={{ background: "#fff" }}>
                        <header id="faq">
                            <h1 className='pt-5 pb-5 fw-bold fst-italic text-uppercase text-center'>FAQ</h1>
                            <h4 className='p-3'>Why did you choose Solana?
                                <p><small style={{ color: "#000" }}>› Fast network speeds, reduced gas fees, and a rapidly growing community!</small></p>
                            </h4>
                            <h4 className='p-3'>How do I purchase a Boto NFT?
                                <p><small style={{ color: "#000" }}>› 1. Download the Phantom, Solflare, Slope or Sollet wallet extension for your browser(Google Chrome recommended).</small></p>
                                <p><small style={{ color: "#000" }}>› 2. Buy Solana(SOL) on an exchange like FTX, Binance, Coinbase etc and send it to your Phantom, Solflare, Slope or Sollet wallet Public Address.</small></p>
                                <p><small style={{ color: "#000" }}>› 3. Connect your Wallet to our website by clicking on the Connect button.</small></p>
                                <p><small style={{ color: "#000" }}>› 4. Click the “Connect Wallet” button on https://botonft.com and approve the transaction in your Wallet.</small></p>
                            </h4>
                            <h4 className='p-3'>How do you connect your wallet?
                                <p><small style={{ color: "#000" }}>› Click the "Connect wallet" button at the top of the page and follow the prompts. You must have one of the wallets listed above on your device.</small></p>
                            </h4>
                            <h4 className='p-3'>When can you mint a Boto NFT?
                                <p><small style={{ color: "#000" }}>› The sale will begin May 27th at 5 UTC.</small></p>
                            </h4>
                            <h4 className='p-3'>What was the price for minting?
                                <p><small style={{ color: "#000" }}>› Only 0.4 Sol</small></p>
                            </h4>

                        </header>
                        <div id="video">
                            <iframe width={560} height={315} title="Boto NFT" src="https://www.youtube.com/embed/id9SuYbDAis" allow="autoplay"></iframe>
                        </div>
                    </Col>
                    <Col xs lg={12} style={{ background: "#fff" }}>
                        <header id="roadmap">
                            <div className='text-center pt-4'>
                                <h1 className='pt-5 pb-5 fw-bold fst-italic text-uppercase text-center'>ROADMAP</h1>
                                <h4 className='p-3'>⟫ It will be released after the assembly process with the help of the community on our official discord. ⟪</h4>
                            </div>
                        </header>
                    </Col>
                    <Col xs lg={12} style={{ background: "#fff" }}>
                        <header>
                            <div className='text-center pt-4'>
                                <a className="btn text-uppercase fw-bold p-4 m-1 h2" href="https://twitter.com/botonft" rel="noreferrer" target={'_blank'} role="button">
                                    <FontAwesomeIcon style={{ color: "#000" }} icon={faTwitter}></FontAwesomeIcon> Twitter
                                </a>
                                <a className="btn text-uppercase fw-bold p-4 m-1" href="https://discord.gg/UpUZuHMTaV" rel="noreferrer" target={'_blank'} role="button">
                                    <FontAwesomeIcon style={{ color: "#000" }} icon={faDiscord}></FontAwesomeIcon> Discord
                                </a>
                                <a className="btn text-uppercase fw-bold p-4 m-1" href="https://www.instagram.com/botonft/" rel="noreferrer" target={'_blank'} role="button">
                                    <FontAwesomeIcon style={{ color: "#000" }} icon={faInstagram}></FontAwesomeIcon> Instagram
                                </a>
                                <a className="btn text-uppercase fw-bold p-4 m-1" href="https://www.tiktok.com/@botonft/video/7097258222087720198?is_copy_url=1&is_from_webapp=v1/" rel="noreferrer" target={'_blank'} role="button">
                                    <FontAwesomeIcon style={{ color: "#000" }} icon={faTiktok}></FontAwesomeIcon> TikTok
                                </a>
                            </div>
                        </header>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}
export default AboutPage;