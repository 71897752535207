import { Navbar, Container, Nav } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faDiscord, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

const Navbarcomp = () => {
    return (
        <div>
            <Navbar id="home" className="fixed-top" collapseOnSelect expand="lg" style={{ background: "#99ffff" }} sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="./img/Botto_NFT_transparent.png"
                            width="130"
                            className="d-inline-block align-top"
                            alt="Boto NFT"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto justify-content-center fw-bold fst-italic">
                            <Nav.Link className="text-uppercase p-4 h5" href="#about"> WHAT ARE BOTO NFT?</Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="#faq">Faq</Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="#roadmap">Roadmap</Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="#video">Presentation video</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href="https://twitter.com/botonft" target={'_blank'}>
                                <FontAwesomeIcon icon={faTwitter} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link href="https://discord.gg/UpUZuHMTaV" target={'_blank'}>
                                <FontAwesomeIcon icon={faDiscord} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link href="https://www.instagram.com/botonft/" target={'_blank'}>
                                <FontAwesomeIcon icon={faInstagram} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link href="https://www.tiktok.com/@botonft/video/7097258222087720198?is_copy_url=1&is_from_webapp=v1/" target={'_blank'}>
                                <FontAwesomeIcon icon={faTiktok} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
export default Navbarcomp;